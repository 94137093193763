import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

const useIdleTimeout = ({ onIdle, idleTime = 1, callback }) => {
  const idleTimeout = 1000 * idleTime;
  const [isIdle, setIdle] = useState(false);
  const handleIdle = () => {
    setIdle(true);
    callback();
  };
  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle: 10000,
    onPrompt: onIdle,
    onIdle: handleIdle,
    throttle: 500,
  });
  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};
export default useIdleTimeout;
