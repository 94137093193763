import { Button, theme } from 'antd';
import PropTypes from 'prop-types';

const { useToken } = theme;
const BaseButton = props => {
  const { token } = useToken();
  const {
    text,
    onClick,
    hasIcon,
    icon,
    bg = token.colorPrimary,
    isDisabled = false,
    width = 'initial',
    type = 'primary',
    loading = false,
    ...rest
  } = props;
  if (hasIcon) {
    return (
      <Button
        type={type}
        loading={loading}
        style={{
          backgroundColor: bg,
          height: '40px',
          textTransform: 'capitalize',
          width: width,
          boxShadow: 'none',
        }}
        icon={icon}
        onClick={onClick}
        disabled={isDisabled}
        {...rest}
      >
        {text}
      </Button>
    );
  }
  return (
    <Button
      type={type ? type : 'primary'}
      loading={loading}
      style={{
        backgroundColor: isDisabled ? '#0000000a' : bg,
        height: '40px',
        textTransform: 'capitalize',
        width: width,
        boxShadow: 'none',
      }}
      onClick={onClick}
      disabled={isDisabled}
      {...rest}
    >
      {text}
    </Button>
  );
};

export default BaseButton;

BaseButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  hasIcon: PropTypes.bool,
  icon: PropTypes.node,
  bg: PropTypes.string,
  isDisabled: PropTypes.bool,
  width: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
};
