/* eslint-disable func-style */
import { takeLatest, put, call } from 'redux-saga/effects';
import { execute } from 'api';

import { setLoginData, loginSuccess, loginFailed, loadLogOut } from './slice';
import { setUserData } from 'redux/slices/appSlice';
import { getLoginRequest, getLogoutRequest } from 'api/requestCreators/login';
import { showAlert } from 'redux/slices/uiSlice';
import { getDeviceData } from './utils';

function* handleLoginRequests(action) {
  const { payload } = action;
  const user = {
    ...payload,
    device: getDeviceData(),
  };
  try {
    const { data } = yield call(execute, getLoginRequest(user));

    const userData = data?.data?.user;
    localStorage.setItem('user', JSON.stringify(userData));
    yield put(loginSuccess(data.data.token));
    yield put(setUserData(userData));
    showAlert({
      message: 'This is a success message',
      type: 'success',
    });
  } catch (error) {
    yield put(loginFailed(error.response?.data?.error));
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}
function* handleLogoutRequests() {
  try {
    yield call(execute, getLogoutRequest());
    localStorage.clear();
    window.location.replace('/login');
  } catch (error) {
    localStorage.clear();
    window.location.replace('/login');
    if (error.response?.data?.message) {
      yield put(
        showAlert({
          message: error.response?.data?.message,
          type: 'error',
        })
      );
    }
  }
}
export function* watchLoginPageRequests() {
  yield takeLatest(setLoginData.type, handleLoginRequests);
  yield takeLatest(loadLogOut.type, handleLogoutRequests);
}
